import { useDocumentVisibility } from '@vueuse/core'

const AUTO_REFRESH_DURATION = 4 * 60 * 60 * 1000

function checkElapsedTime(windowActiveTime: string) {
  const currentTime = new Date()
  const activeTime = new Date(windowActiveTime)
  const elapsedTime = currentTime.getTime() - activeTime.getTime()
  return elapsedTime >= AUTO_REFRESH_DURATION
}

function reloadNtp() {
  sessionStorage.clear()
  window.location.reload()
}

export const useAutoRefresh = () => {
  if (import.meta.browser) {
    const activeTab = useDocumentVisibility()
    const storedActiveTime = sessionStorage.getItem('windowActiveTime')
    const windowActiveTime = storedActiveTime
      ? new Date(storedActiveTime)
      : new Date()
    sessionStorage.setItem('windowActiveTime', windowActiveTime.toString())
    watch(
      activeTab,
      (current) => {
        if (current === 'visible') {
          const shouldAutoReload = checkElapsedTime(windowActiveTime.toString())
          if (shouldAutoReload) {
            reloadNtp()
          }
        }
      },
      { immediate: true }
    )
  }
}
